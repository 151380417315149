<template>
  <div>
    <hr />
    <div class="row" v-if="$parent.det_autorizaciones.length > 0">
      <table class="table table-sm table-hover">
        <thead class="thead-dark">
          <tr class="text-center">
            <th scope="col">Usuario Solicita</th>
            <th scope="col">Fecha Solicita</th>
            <th scope="col">Tipo autorización</th>
            <th scope="col">Observacion</th>
            <th scope="col">Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in $parent.det_autorizaciones" :key="item.id">
            <td class="text-center">{{ item.name }}</td>
            <td class="text-center">{{ moment(item.created_at).format("YYYY-MM-DD HH:mm:ss") }}</td>
            <td class="text-center">{{ item.tipoAuto }}</td>
            <td class="text-center">{{ item.observacion_autoriza }}</td>
            <td class="text-center">{{ item.estadoAuto }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-else>
      <div class="alert alert-warning col-md-12">
        <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
        No se encontraron autorizaciones para este Viaje!
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "TifTurnoAutorizaciones",

  data() {
    return {
      det_autorizaciones: {},
      moment: moment,
    };
  },
  methods: {},

  mounted() {},
};
</script>
